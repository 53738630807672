.OngkYa_icon {
  background: var(--color-common);
  -webkit-mask-image: var(--maskUrl);
  mask-image: var(--maskUrl);
  text-indent: 100%;
  white-space: nowrap;
  display: inline-block;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.BOBUQq_wrapper {
  border-image-slice: 1;
  border-image-source: linear-gradient( to left, rgba(0, 0, 0, 0) 0%, var(--color-common) 25%, var(--color-common) 75%, rgba(0, 0, 0, 0) 100% );
  text-align: center;
  border-top: 1px solid ;
  margin: 5rem 0 0;
  padding: 3rem;
}

.BOBUQq_link_icon {
  height: var(--font-size-m);
  width: var(--font-size-m);
  margin: 0;
  line-height: 1.1rem;
  display: inline-block;
}

.BOBUQq_link:hover .BOBUQq_link_icon {
  background: var(--color-accent);
}

.BOBUQq_link:hover {
  color: var(--color-accent);
}

.BOBUQq_version_hint {
  color: var(--color-inferior-alternate);
  text-align: right;
  font-size: var(--font-size-xs);
  margin: .5rem 0 0;
}

.MqZHCq_header {
  flex-direction: column;
  padding: 3rem 1rem 1.5rem;
  display: flex;
}

.MqZHCq_avatar {
  display: block;
}

.MqZHCq_avatar_img {
  aspect-ratio: 1 / 1;
  border: 1px solid var(--color-inferior-alternate);
  height: 9rem;
  width: auto;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.MqZHCq_info {
  text-align: center;
}

.MqZHCq_name {
  font-size: 3rem;
  font-weight: var(--font-weight-light);
  display: block;
}

@media print, (min-width: 46rem) {
  .MqZHCq_header {
    flex-direction: row;
  }

  .MqZHCq_avatar {
    max-width: 30%;
    flex: 0 0 30%;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1.5rem 0 0;
    display: flex;
  }

  .MqZHCq_avatar_img {
    margin: unset;
    max-height: 8rem;
  }

  .MqZHCq_info {
    max-width: 70%;
    text-align: left;
    flex: 0 0 70%;
    padding: 0 1rem 0 0;
  }

  .MqZHCq_name {
    word-spacing: 100vw;
  }
}

.y8lgcG_profile_list {
  flex-direction: column;
  display: flex;
}

.y8lgcG_profile {
  flex-grow: 0;
  margin: .5rem 0 0;
}

.y8lgcG_profile_link {
  cursor: pointer;
  width: fit-content;
  align-items: center;
  display: flex;
}

.y8lgcG_profile_link:hover {
  color: var(--color-accent-alternate);
}

.y8lgcG_profile_link:hover .y8lgcG_icon {
  background-color: var(--color-accent);
}

.y8lgcG_icon {
  height: 1.4rem;
  width: 1.4rem;
  margin: 0 .3rem 0 0;
  display: block;
}

.y8lgcG_icon img {
  width: 24px;
  height: 24px;
}

@media print {
  .y8lgcG_profile {
    display: none;
  }

  .y8lgcG_profile:nth-child(-n+2) {
    display: block;
  }
}

.b6ckRW_h2 {
  border-image-slice: 1;
  border-image-source: linear-gradient( to left, rgba(0, 0, 0, 0), var(--color-inferior-alternate) 75% );
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  border-bottom: 1px solid ;
  margin: 0 0 1.5rem;
  padding: 0 0 .2rem;
}

.v9HIDG_sections {
  padding: 1rem;
}

.VCN7pq_detail {
  flex-direction: row;
  margin: 0 0 .6rem;
  display: flex;
}

.VCN7pq_icon {
  max-height: 1.6rem;
  max-width: 1.2rem;
  background: var(--color-common);
  flex: 0 0 1.2rem;
  margin: .15rem .5rem 0 0;
  display: block;
}

.VCN7pq_email {
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
  display: block;
}

.VCN7pq_address_detail {
  display: block;
}

.GxTgLW_language_list {
  display: block;
}

.GxTgLW_language_item {
  margin-bottom: .4rem;
  display: block;
}

.GxTgLW_language {
}

.GxTgLW_fluency {
  white-space: nowrap;
  color: var(--color-accent);
  font-weight: var(--font-weight-light);
}

.QKonIq_detail {
  display: block;
}

.MPMxcW_situation_list {
  margin: 0;
  padding: 0;
}

.MPMxcW_situation_title {
  margin: 0 0 var(--font-size-xs) 0;
  padding: 0;
}

.MPMxcW_position {
  text-transform: uppercase;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
  display: block;
}

.MPMxcW_company {
  font-size: var(--font-size-m);
  color: var(--color-accent);
  margin: 0;
  padding: 0;
  display: block;
}

.MPMxcW_period {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-light);
  color: var(--color-accent);
  margin: 0;
  padding: 0;
}

.MPMxcW_period time:first-child:after {
  content: " - ";
}

.MPMxcW_situation_detail {
  margin: 0 0 var(--font-size-l) 0;
  padding: 0;
}

.MPMxcW_description {
  margin: 0 0 var(--font-size-xs) 0;
}

.MPMxcW_highlights {
  display: block;
}

.MPMxcW_highlight {
  margin: 0 0 var(--font-size-xs) 1.2em;
  position: relative;
}

.MPMxcW_highlight:last-child {
  margin-bottom: 0;
}

.MPMxcW_highlight:before {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--color-accent);
  border-radius: 100%;
  display: block;
  position: absolute;
  top: .45em;
  left: -1em;
}

@media (min-width: 75rem) {
  .MPMxcW_situation_list {
    flex-flow: wrap;
    display: flex;
  }

  .MPMxcW_situation_title {
    text-align: right;
    max-width: 30%;
    border-right: 1px solid var(--color-inferior-alternate);
    padding: 0 1rem var(--font-size-xl) 0;
    flex: 30%;
    margin: 0;
    position: relative;
  }

  .MPMxcW_situation_title:before {
    content: "";
    height: 11px;
    width: 11px;
    background-color: var(--color-inferior);
    border: 1px solid var(--color-inferior-alternate);
    border-radius: 50%;
    position: absolute;
    top: 1.5rem;
    right: -6px;
  }

  .MPMxcW_situation_title:last-of-type {
    border-image: linear-gradient( to bottom, var(--color-inferior-alternate) 50%, rgba(0, 0, 0, 0) ) 1 100%;
  }

  .MPMxcW_situation_title:first-of-type {
    border-image: linear-gradient( to bottom, rgba(0, 0, 0, 0), var(--color-inferior-alternate) 1em ) 1 100%;
  }

  .MPMxcW_position {
    direction: rtl;
  }

  .MPMxcW_situation_detail {
    flex: 70%;
    margin: 0;
    padding: 0 0 5rem 1rem;
  }
}

.OGMj0W_title {
  text-transform: uppercase;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
}

.OGMj0W_summary {
  margin: .4rem 0;
}

.OGMj0W_awarder {
  font-size: var(--font-size-m);
  color: var(--color-accent);
  display: block;
}

.OGMj0W_date {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-light);
  color: var(--color-accent);
  display: block;
}

._5l06ia_layout {
  background-color: var(--color-bg-highlight);
  max-width: var(--bp-l);
  flex-direction: column;
  margin: 0 auto;
  animation: _5l06ia_fadein 1.5s;
  display: flex;
}

._5l06ia_header, ._5l06ia_aside, ._5l06ia_main, ._5l06ia_footer {
  padding: 0 1rem;
}

@media print, (min-width: 46rem) {
  ._5l06ia_layout {
    flex-flow: wrap;
    justify-content: space-around;
    display: flex;
  }

  ._5l06ia_header {
    flex: 0 0 100%;
    order: 1;
  }

  ._5l06ia_aside {
    max-width: 30%;
    flex: 0 0 30%;
    order: 2;
    padding: 0 0 0 1rem;
  }

  ._5l06ia_main {
    max-width: 70%;
    flex: 0 0 70%;
    order: 3;
    padding: 0 1rem 0 0;
  }

  ._5l06ia_footer {
    flex: 0 0 100%;
    order: 4;
  }
}

@media (min-width: 75rem) {
  ._5l06ia_layout {
    border: 1px solid rgba(0, 0, 0, .09);
    border-radius: .3rem;
    margin: 5rem auto;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, .035), 0 6.7px 5.3px rgba(0, 0, 0, .047), 0 12.5px 10px rgba(0, 0, 0, .06), 0 22.3px 17.9px rgba(0, 0, 0, .07), 0 41.8px 33.4px rgba(0, 0, 0, .086), 0 100px 80px rgba(0, 0, 0, .12);
  }
}

@keyframes _5l06ia_fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

:where(:not(iframe):not(canvas):not(img):not(svg):not(video):not(svg *):not(symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  -ms-appearance: revert;
  appearance: revert;
}

::-ms-input-placeholder {
  color: unset;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:root {
  --color-common: #2c3531;
  --color-accent: #116466;
  --color-accent-alternate: #d1e8e2;
  --color-inferior: #d9b08c;
  --color-inferior-alternate: #ffcb9a;
  --color-common: #222629;
  --color-accent: #86c232;
  --color-accent-alternate: #61892f;
  --color-inferior: #474b4f;
  --color-inferior-alternate: #6b6e70;
  --color-bg-main: #eaeaea;
  --color-bg-highlight: #fff;
  --font-size-xs: .8rem;
  --font-size-s: 1rem;
  --font-size-m: 1.2rem;
  --font-size-l: 1.4rem;
  --font-size-xl: 2rem;
  --font-size-xxl: 2rem;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --bp-m: 46rem;
  --bp-l: 75rem;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --color-common: #fff;
    --color-bg-main: #15141a;
    --color-bg-highlight: #2b2a33;
  }
}

html {
  background-color: var(--color-bg-main);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--color-common);
  font-size: var(--font-size-m);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@media print {
  @page {
    font-size: 24px;
    size: 1092px 1544px;
  }
}



/*# sourceMappingURL=index.9acdff01.css.map */
